import React, { Component } from "react";
import {Link} from "react-router-dom";

import FooterEventsOnScroll from "./FooterEventsOnScroll";
import {animateScroll as scroll} from 'react-scroll';
import {withRouter} from "react-router-dom";
import ContactBlock from "./ContactBlock/ContactBlock";

import logoBackground from "./images/alkima-footer-logo-hintergrund.svg"
import mobileRocket from "./images/alkima-footer-rakete-mobil.svg"
import desktopRocket from "./images/alkima-footer-rakete-desktop.svg"

import axios from "axios";
import API from "../../api";
import ThemeGeneralSettingsContext from "../../ThemeGeneralSettingsContext.js";

import './Footer.scss';

class Footer extends Component {
    static contextType = ThemeGeneralSettingsContext

    state = {
        services_menu: [],
        projects_menu: [],
        success_stories_menu: [],
        information_menu: [],
        legal_menu: [],
        footer_data: [],
        upBtnDarkColor: false,
        buttonsFixedPosition: false,
        data_load_status: false,
        active_menus: [],
    };

    loadFooterData = async () => {
        let servicesMenuId
        let projectsMenuId
        let successStoriesMenuId
        let informationMenuId
        let legalMenuId

        // Get footer data
        await axios.get(API.basePath+'/wp-json/acf/v3/options/footer-general-settings')
            .then(response => {
                this.setState({
                    footer_data: response.data.acf,
                    data_load_status: true
                });
                servicesMenuId = response.data.acf.services_menu_id
                projectsMenuId = response.data.acf.projects_menu_id
                successStoriesMenuId = response.data.acf.success_stories_menu_id
                informationMenuId = response.data.acf.information_menu_id
                legalMenuId = response.data.acf.legal_menu_id
            })
            .catch(error => {
                console.log(error);
            });

        // Get services menu items
        if (servicesMenuId) {
            await axios.get(API.basePath+`/wp-json/wp-api-menus/v2/menus/${servicesMenuId}`)
            .then(response => {
                this.setState({
                    services_menu: response.data.items
                });
            })
            .catch(error => {
                console.log(error);
            });
        }

        // Get projects menu items
        if (projectsMenuId) {
            await axios.get(API.basePath+`/wp-json/wp-api-menus/v2/menus/${projectsMenuId}`)
            .then(response => {
                this.setState({
                    projects_menu: response.data.items
                });
            })
            .catch(error => {
                console.log(error);
            });
        }

        // Get success stories menu items
        if (successStoriesMenuId) {
            await axios.get(API.basePath+`/wp-json/wp-api-menus/v2/menus/${successStoriesMenuId}`)
            .then(response => {
                this.setState({
                    success_stories_menu: response.data.items
                });
            })
            .catch(error => {
                console.log(error);
            });
        }

        // Get information menu items
        if (informationMenuId) {
            await axios.get(API.basePath+`/wp-json/wp-api-menus/v2/menus/${informationMenuId}`)
            .then(response => {
                this.setState({
                    information_menu: response.data.items
                });
            })
            .catch(error => {
                console.log(error);
            });
        }

        // Get legal menu items
        if (legalMenuId) {
            await axios.get(API.basePath+`/wp-json/wp-api-menus/v2/menus/${legalMenuId}`)
            .then(response => {
                this.setState({
                    legal_menu: response.data.items
                });
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    componentDidMount() {
        this.loadFooterData()
    }

    showMenu(key) {
        let menusState = [...this.state.active_menus]

        if (menusState.includes(key)) {
            let index = menusState.indexOf(key);
            menusState.splice(index, 1);
        } else {
            menusState.push(key)
        }

        this.setState({
            active_menus: menusState,
        });
    }

    upButtonColor = (status = false) => {
        if( this.state.upBtnDarkColor !== status) {
            this.setState({
                upBtnDarkColor: status
            });
        }
    };

    buttonsPosition = (status = false) => {
        if( this.state.buttonsFixedPosition !== status) {
            this.setState({
                buttonsFixedPosition: status
            });
        }
    };

    render() {
        const { certificates } = this.context
        const { shopware_developer, shopware_designer, usercentrics, erecht24 } = certificates

        let services_nav_items = this.state.services_menu.map((item, key) =>
            <li key={item.id}>
                <Link to={`/${item.object_slug}`}>{item.title}</Link>
            </li>
        );

        let projects_nav_items = this.state.projects_menu.map((item, key) =>
            <li key={item.id}>
                <Link to={`/${item.object_slug}`}>{item.title}</Link>
            </li>
        );

        let success_stories_nav_items = this.state.success_stories_menu.map((item, key) =>
            <li key={item.id}>
                {item.type === 'custom' ? 
                    <Link to={item.url}>{item.title}</Link>
                :
                    <Link to={`/${item.object_slug}`}>{item.title}</Link>
                }
            </li>
        );

        let information_nav_items = this.state.information_menu.map((item, key) =>
            <li key={item.id}>
                {item.type === 'custom' ?
                    <Link to={item.url}>{item.title}</Link>
                    :
                    <Link to={`/${item.object_slug}`}>{item.title}</Link>
                }
            </li>
        );

        let legal_nav_items = this.state.legal_menu.map((item, key) =>
            <li key={item.id}>
                <Link to={`/${item.object_slug}`}>{item.title}</Link>
            </li>
        );

        if (!this.state.data_load_status) {
            return(
                <div>Loading...</div>
            );
        }

        const {
            contact_block,
            footer_logo,
            follow_us_text,
            linkedin_profile_url,
            twitter_profile_url,
            contacts_column_title,
            footer_phone,
            footer_email,
            footer_address,
            services_menu_title,
            projects_menu_title,
            success_stories_menu_title,
            information_menu_title,
            footer_copyright_text,
            feedback_button_title,
            scroll_up_button_title,
        } = this.state.footer_data;

        return (
            <div className='footerWithContactBlock'>
                {this.props.contactBlockStatus ? (
                    <ContactBlock
                        data={contact_block}
                        smallButtonStatus={this.props.contactButtonStatus}
                        preventContactBlockAnimation={this.props.preventContactBlockAnimation}
                    />
                ) : (
                    ''
                )}

                <footer className={`footer page__footer${this.state.buttonsFixedPosition ? ' page__footer--fixed-buttons':''}`}>
                    <div className="footer__main">
                        <div className="footer__top">
                            <div className="footer__logo">
                                <Link to="/" dangerouslySetInnerHTML={{__html: footer_logo}} aria-label="To home page"/>
                            </div>
                            <img className="footer__top_bg" src={logoBackground} alt="alkima-footer-logo-hintergrund" />
                        </div>

                        <div className="footer__content">
                            <div className="footer__content--top">
                                <div className="footer__col footer__item footer__contacts">
                                    <div 
                                        onClick={() => this.showMenu(1)}
                                        className={`footer__item_title${(this.state.active_menus.includes(1)) ? ' opened' : ""}`} 
                                    >
                                        <p>{contacts_column_title}</p>
                                    </div>
                                    <div 
                                        className="footer__item_inner"
                                        style={{ height: 
                                            this.state.active_menus.includes(1) ? document.getElementById('footerMenu1').offsetHeight : 0 
                                        }}
                                    >
                                        <div className="footer__item_menu" id="footerMenu1">
                                            <a className="footer__contacts_phone" href={`tel:${footer_phone.replace(/\s/g, "")}`}>
                                                <i className="icon-phone"></i>
                                                <span>{footer_phone}</span>
                                            </a>
                                            <a className="footer__contacts_email" href={`mailto:${footer_email}`}>
                                                <i className="icon-envelope"></i>
                                                <span>{footer_email}</span>
                                            </a>
                                            <div className="footer__contacts_address">
                                                <p>{footer_address.title}</p>
                                                {footer_address.addresses.map((item, key) =>
                                                    <div key={`address--${key}`}>
                                                        <i className="icon-location"></i>
                                                        <div dangerouslySetInnerHTML={{__html: item.address}}/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav className="footer__col footer__item">
                                    <div 
                                        onClick={() => this.showMenu(2)}
                                        className={`footer__item_title${(this.state.active_menus.includes(2)) ? ' opened' : ""}`} 
                                    >
                                        <p>{services_menu_title}</p>
                                    </div>
                                    <div 
                                        className="footer__item_inner"
                                        style={{ height: 
                                            this.state.active_menus.includes(2) ? document.getElementById('footerMenu2').offsetHeight : 0 
                                        }}
                                    >
                                        <ul className="footer__item_menu" id="footerMenu2">
                                            {services_nav_items}
                                        </ul>
                                    </div>
                                </nav>
                                <div className="footer__col">
                                    <nav className="footer__item">
                                        <div 
                                            onClick={() => this.showMenu(3)}
                                            className={`footer__item_title${(this.state.active_menus.includes(3)) ? ' opened' : ""}`} 
                                        >
                                            <p>{projects_menu_title}</p>
                                        </div>
                                        <div 
                                            className="footer__item_inner"
                                            style={{ height: 
                                                this.state.active_menus.includes(3) ? document.getElementById('footerMenu3').offsetHeight : 0 
                                            }}
                                        >
                                            <ul className="footer__item_menu" id="footerMenu3">
                                                {projects_nav_items}
                                            </ul>
                                        </div>
                                    </nav>
                                    
                                    <nav className="footer__item">
                                        <div 
                                            onClick={() => this.showMenu(4)}
                                            className={`footer__item_title${(this.state.active_menus.includes(4)) ? ' opened' : ""}`} 
                                        >
                                            <p>{success_stories_menu_title}</p>
                                        </div>
                                        <div 
                                            className="footer__item_inner"
                                            style={{ height: 
                                                this.state.active_menus.includes(4) ? document.getElementById('footerMenu4').offsetHeight : 0 
                                            }}
                                        >
                                            <ul className="footer__item_menu" id="footerMenu4">
                                                {success_stories_nav_items}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <nav className="footer__col footer__col--last footer__item">
                                    <div 
                                        onClick={() => this.showMenu(5)}
                                        className={`footer__item_title${(this.state.active_menus.includes(5)) ? ' opened' : ""}`} 
                                    >
                                        <p>{information_menu_title}</p>
                                    </div>
                                    <div 
                                        className="footer__item_inner"
                                        style={{ height: 
                                            this.state.active_menus.includes(5) ? document.getElementById('footerMenu5').offsetHeight : 0 
                                        }}
                                    >
                                        <ul className="footer__item_menu" id="footerMenu5">
                                            {information_nav_items}
                                            <li>
                                               <a
                                                href="/#"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    window.UC_UI.showSecondLayer()
                                                }}
                                                >
                                                <span>Cookie-Einstellungen</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="footer__content--bottom">
                                <div className="footer__follow-us">
                                    <span>{follow_us_text}</span>
                                    <div >
                                        <a 
                                            href={linkedin_profile_url} 
                                            target="_blank" 
                                            rel="nofollow noopener" 
                                            aria-label="Linkedin"
                                        >
                                            <i className="icon-linkedin"></i>
                                        </a>
                                        <a
                                        href={twitter_profile_url}
                                        target="_blank"
                                        rel="nofollow noopener"
                                        aria-label="Twitter"
                                        >
                                            <i className="icon-svg">
                                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.0488285 0L7.7703 9.92977L0 17.9996H1.7488L8.55197 10.9331L14.0503 18H20L11.8436 7.51351L19.0764 0H17.3276L11.0623 6.507L5.99975 0H0.0488285ZM2.62074 1.2389H5.35473L17.4269 16.7607H14.6937L2.62074 1.2389Z"
                                                        fill="url(#paint_linear)" className="icon-svg__path" />
                                                    <defs>
                                                        <linearGradient id="paint_linear" x1="20" y1="18" x2="2.09945" y2="-1.8895"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FC6076" />
                                                            <stop offset="0.484375" stopColor="#FF4D66" />
                                                            <stop offset="1" stopColor="#FF6826" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </i>
                                        </a>
                                    </div>
                                </div>
                                <div className="footer__honors">
                                    <picture className="footer__honors-picture">
                                        <img 
                                            className="certificate" 
                                            src={shopware_developer.url} 
                                            alt={shopware_developer.alt} 
                                        />
                                    </picture>
                                    <picture className="footer__honors-picture">
                                        <img 
                                            className="certificate" 
                                            src={shopware_designer.url} 
                                            alt={shopware_designer.alt} 
                                        />
                                    </picture>
                                    <picture className="footer__honors-picture usercentrics">
                                        <img 
                                            className="certificate" 
                                            src={usercentrics.url} 
                                            alt={usercentrics.alt} 
                                        />
                                    </picture>
                                    <picture className="footer__honors-picture erecht">
                                        <img 
                                            className="certificate" 
                                            src={erecht24.url}
                                            alt={erecht24.alt}
                                        />
                                    </picture>
                                </div>
                            </div>
                        </div>         

                        <img className="footer__mobile-rocket" src={mobileRocket} alt="alkima-footer-rakete-mobil" />
                        <img className="footer__desktop-rocket" src={desktopRocket} alt="alkima-footer-rakete-desktop" />    
                    </div>

                    <div className="footer__bottom">
                        <nav>
                            <ul>
                                {legal_nav_items}
                            </ul>
                        </nav>
                        <p>{footer_copyright_text}</p>
                    </div>

                    <div className={`page__footer-btns${(this.props.location.pathname === '/kontakt') ? ' page__footer-btns--hidden' : ''}${this.state.buttonsFixedPosition ? ' page__footer-btns--fixed':''}`}>
                        <div className={`footer__btn${this.state.upBtnDarkColor ? ' footer__btn--dark-color' : ''} footer__btn-scroll-top`}>
                            <button onClick={()=>{scroll.scrollToTop()}}>
                                <div className="footer__btn-icon">
                                    <i className="icon-angle-up"></i>
                                </div>
                                <div className="footer__btn-text">{scroll_up_button_title}</div>
                            </button>
                        </div>
                        {
                            (this.props.location.pathname !== '/kontakt') ? (
                                <div className="footer__btn footer__btn-feedback">
                                    <Link to="/kontakt">
                                        <div className="footer__btn-icon">
                                            <i className="icon-envelope"></i>
                                        </div>
                                        <div className="footer__btn-text">{feedback_button_title}</div>
                                    </Link>
                                </div>
                            ) : ('')
                        }
                    </div>
                    <div id="google-reviews-badge" className="elfsight-app-e3e47731-efdb-4d04-87bd-6d5457718f26"></div>
                    <FooterEventsOnScroll {...this.props} changeButtonStatus={this.upButtonColor} changeButtonsPosition={this.buttonsPosition}/>
                </footer>
            </div>
        );
    }
}

export default withRouter(Footer);
