import React, { Component } from "react";

import "./Perfectes.scss";
export default class Perfectes extends Component {
  ref = null;

  state = {
    isActive: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      this.scrollPosition
    );
  }

  scrollPosition = (e) => {
    e.preventDefault();
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 100 && !this.state.isActive && window.innerWidth > 992) {
      this.setState({
        isActive: true,
      });

      window.scroll({
        behavior: 'smooth',
        top: this.ref.offsetTop
      });
    }
  };

  render() {
    const {
      title,
      text,
      main_title,
      design_sides } = this.props.content;

    return (
      <>
        <section className="perfectes" ref={(ref) => this.ref = ref} id="scrollPosition">
          <div className="perfectes__bg"></div>
          <div className="perfectes__wrapper">
            <div className="perfectes__content">
              <div className={`perfectes__content-bg${this.state.isActive ? " perfectes__content-bg-animate" : " "}`}></div>
              <div className={`perfectes__content-bg${this.state.isActive ? " perfectes__content-bg-second" : " "}`}></div>
              {window.innerWidth > 992
                ?
                <div   className={`perfectes__content-title${this.state.isActive
                  ? " perfectes__content-title-animate"
                  : " "
                }`}>
                  <h1 className="main-title">
                    PERFEKTES DES
                    <span className={this.state.isActive ? " span" : " "}>i</span>
                    GN <br />  OHNE KOMPROM
                    <span className={this.state.isActive ? " span" : " "}>i</span>
                    SSE
                  </h1>
                  <span className="perfectes__content-title-bg">
                    {title}
                  </span>
                  <span className="perfectes__content-title-bg perfectes__content-title-bg-white">
                    {title}
                  </span>
                </div>

                :
                <h2 className="perfectes__content-title--mobile">{title}</h2>
              }
              <div
                className={`perfectes__content-text${this.state.isActive ? " perfectes__content-text-animate" : " "
                  }`}
                dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div
              className={`perfectes__title${this.state.isActive ? " perfectes__title-animate" : " "
                }`}
            >
              <img src={main_title.image.url} alt={main_title.alt} />
            </div>
            <div className="perfectes__text">
              <div className="perfectes__text-inner">
                <span
                  className={`text-item text-item-yellow${this.state.isActive ? " text-item-yellow-animate" : " "
                    }`}
                >{design_sides.first}
                  <span className="text-item-bg">{design_sides.first}</span>
                </span>
                <span
                  className={`text-item text-item-pink${this.state.isActive ? " text-item-pink-animate" : " "
                    }`}
                >{design_sides.second}
                  <span className="text-item-bg">{design_sides.second}</span>
                </span>
                <span
                  className={`text-item text-item-red${this.state.isActive ? " text-item-red-animate" : " "
                    }`}
                >{design_sides.third}
                  <span className="text-item-bg">{design_sides.third}</span>
                </span>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
